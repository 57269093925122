import {ChangeEvent, useEffect, useState} from 'react'

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue)
  const [label, setLabel] = useState(initialValue)

  useEffect(() => {
    setLabel(initialValue)
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onChange) onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
    //disabling exhaustive-deps here so onChange function does not trigger repeat rerenderings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, value])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.dataset.value || e.target.value)
    setLabel(e.target.dataset.label || e.target.value)
  }

  return <input {...props} value={label} onChange={handleInputChange} />
}
export default DebouncedInput
