// Get latest confidence '3' heart rate data point

export type HeartRateData = {
  active: boolean
  confidence_level: number
  created_at: string | Date
  heart_rate: string | null
  id: number
  participant_id: number
  tag: any
  updated_at: string | Date
}[]

export function getLatestHeartRate(array: HeartRateData, id: number | undefined): string | null {
  if (!array) {
    return ''
  }
  const sortedItems = array
    .filter((item) => item.participant_id === id)
    .filter((item) => item.confidence_level === 3 || item.confidence_level === 2)
    .sort((a, b) => b?.confidence_level - a?.confidence_level)

  const latestItem = sortedItems[0]

  return latestItem ? Number(latestItem.heart_rate) + ' BPM' : ''
}

export function getLatestPing(array: HeartRateData): Date | string {
  const latestItemWithConfidenceLevel2Or3 = array
    ?.filter((item) => item.confidence_level === 3 || item.confidence_level === 2)
    .pop()

  return latestItemWithConfidenceLevel2Or3 ? latestItemWithConfidenceLevel2Or3.created_at : 'N/A'
}

/**
 * gets the text label for risklevel by the number value
 */
export const getRiskString = (risk: number) => {
  switch (risk) {
    case 1:
      return 'Low'
    case 2:
      return 'Medium'
    case 3:
      return 'High'
  }
}