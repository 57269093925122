import DebouncedInput from '../DebouncedInput'
import SearchIcon from '@mui/icons-material/Search'
import CancelIcon from '@mui/icons-material/Cancel'

const GlobalSearch = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter?: string
  setGlobalFilter: (value: string) => void
}) => {
  const handleCancel = () => {
    setGlobalFilter('')
  }
  return (
    <div className='relative flex items-center border-b-1 border-table-lightgray'>
      <div className='absolute left-2 flex h-full items-center'>
        <SearchIcon className=' text-table-lightgray' />
      </div>
      <DebouncedInput
        value={globalFilter ?? ''}
        onChange={(value) => setGlobalFilter(String(value))}
        className='bg-transparent p-4 px-10 '
        placeholder='Search all columns...'
        id='global-search'
      />
      {globalFilter !== '' && (
        <div className='absolute right-2 flex h-full items-center'>
          <CancelIcon onClick={handleCancel} className=' cursor-pointer text-table-lightgray' />
        </div>
      )}
    </div>
  )
}
export default GlobalSearch
