import {SerializedError} from '@reduxjs/toolkit'
import {PaginationType} from './types'

export type ApiResponse<T> = {
  status: number
  data: T
}

export type ApiPaginatedResponse<T> = ApiResponse<{
  pagination: {page: number; limit: number; count: number}
  results: T[]
}>

export type ApiRequestState<T> = {
  status: ApiStatus
  error: SerializedError | null
  data: T | null
}

export type ApiPaginationRequestState<T> = {
  status: ApiStatus
  error: SerializedError | null
  data: {
    pagination: PaginationType
    results: T | null
  }
}

export const apiRequestStateDefault: ApiRequestState<any> = {
  status: 'inactive',
  error: null,
  data: null,
}

export const apiRequestPaginationStateDefault: ApiPaginationRequestState<any> = {
  status: 'inactive',
  error: null,
  data: {pagination: {page: 1, count: 0, limit: 0}, results: null},
}

export type ApiStatus = 'inactive' | 'pending' | 'fulfilled' | 'rejected'
