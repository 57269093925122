// Combine n words in a string, lowercase
// the first letter of the first word
// upper case the first letter of all other words
export const setToCamelCasing = (string: string) => {
  if (typeof string !== 'string' || string === null) {
    return ''
  }
  return string
    .split(/\s+/)
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase()
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1)
      }
    })
    .join('')
}

export const capitalizeFirstLetters = (str: string) => {
  if (!str) {
    return ''
  } else {
    return str?.replace(/\b\w/g, (char) => char.toUpperCase())
  }
}

export const titleCase = (string: string) => {
  if (string.length === 0) {
    return string
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}
