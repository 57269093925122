import {FC, ReactNode, useState} from 'react'
import './dashboardFilter.scss'
import {ReactComponent as One} from '../../../../images/1.svg'
import {ReactComponent as OneLTwoR} from '../../../../images/1l-2r.svg'
import {ReactComponent as OneLOneR} from '../../../../images/1l-1r.svg'
import {ReactComponent as TwoLOneR} from '../../../../images/2l-1r.svg'
import {ReactComponent as TwoTOneB} from '../../../../images/2t-1b.svg'
import {ReactComponent as OneTOneB} from '../../../../images/1t-1b.svg'
import {ReactComponent as OneTTwoB} from '../../../../images/1t-2b.svg'
import {ReactComponent as TwoTTwoB} from '../../../../images/2t-2b.svg'
import {ReactComponent as Close} from '../../../../images/close.svg'
import {ListItem, ListItemButton, ListItemIcon, ListItemText, Popover} from '@mui/material'
import {DragDropContext, Draggable, DropResult, Droppable} from 'react-beautiful-dnd'
import {DragIndicator} from '@mui/icons-material'
import clsx from 'clsx'

interface ComponentList {
  key: string
  label: string
  component: ReactNode | any
  props: any
}

interface IProps {
  setOrientation: (orientation: number[]) => void
  setOrientationType: (orientation: string) => void
  orderedComponents: ComponentList[]
  setOrderedComponents: (components: ComponentList[]) => void
}

type LayoutParams = [number, [number, number], 'H' | 'V']

type SvgDataItem = {
  id: number
  icon: JSX.Element
  params: LayoutParams
}

const reorder = (list: ComponentList[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DashboardFilter: FC<IProps> = ({
  setOrientation,
  setOrientationType,
  orderedComponents,
  setOrderedComponents,
}) => {
  const [activeLayoutMenu, setActiveLayoutMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [cardCount, setCardCount] = useState(4)
  const [selectedSvg, setSelectedSvg] = useState<number>(7)

  const handleLayoutClick = (
    e: React.MouseEvent<HTMLElement>,
    cardCount: number,
    orientation: number[],
    orientationType: string
  ) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
    setCardCount(cardCount)
    setOrientation(orientation)
    setOrientationType(orientationType)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onDragEnd = ({destination, source}: DropResult) => {
    if (!destination) return
    const components = reorder(orderedComponents, source.index, destination.index)
    setOrderedComponents(components)
  }

  const svgData: SvgDataItem[] = [
    {id: 0, icon: <OneLTwoR />, params: [3, [1, 2], 'H']},
    {id: 1, icon: <OneLOneR />, params: [2, [1, 1], 'H']},
    {id: 2, icon: <TwoLOneR />, params: [3, [2, 1], 'H']},
    {id: 3, icon: <TwoTOneB />, params: [3, [2, 1], 'V']},
    {id: 4, icon: <OneTOneB />, params: [3, [1, 1], 'V']},
    {id: 5, icon: <OneTTwoB />, params: [3, [1, 2], 'V']},
    {id: 6, icon: <One />, params: [1, [1, 0], 'V']},
    {id: 7, icon: <TwoTTwoB />, params: [4, [2, 2], 'V']},
  ]

  const open = Boolean(anchorEl)
  const id = open ? 'dashboardPopover' : undefined

  return (
    <section className='row relative justify-end px-0' style={{width: '95%', margin: '0 auto'}}>
      <a
        href='#'
        onClick={() => setActiveLayoutMenu(!activeLayoutMenu)}
        className='selected-layout p-3'
      >
        {activeLayoutMenu ? (
          <Close />
        ) : (
          (svgData.find((svg) => svg.id === selectedSvg) || {}).icon || <OneLTwoR />
        )}
      </a>
      <ul className={`layout-options flex flex-row ${activeLayoutMenu ? 'active' : ''}`}>
        {svgData.map((data) => (
          <li
            key={data.id}
            onClick={(e) => {
              handleLayoutClick(e, ...data.params)
              setSelectedSvg(data.id)
            }}
            style={{
              backgroundColor: selectedSvg === data.id ? '#DB7100' : 'transparent',
              borderRadius: '4px',
            }}
          >
            <a href='#' className='block p-3'>
              {data.icon}
            </a>
          </li>
        ))}
      </ul>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable-list'>
            {(provided: any) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {orderedComponents.map((item, index) => (
                  <Draggable key={item.key} draggableId={item.key} index={index}>
                    {(provided, snapshot) => (
                      <ListItem
                        disablePadding
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={clsx({
                          draggingListItem: snapshot.isDragging,
                          listGray: index > cardCount - 1,
                        })}
                      >
                        <ListItemButton>
                          <ListItemText primary={item.label} />
                          <ListItemIcon className='listIcon'>
                            <DragIndicator />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Popover>
    </section>
  )
}

export default DashboardFilter
